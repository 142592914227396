var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zeroRightClassName = "right-scroll-bar-position";
exports.fullWidthClassName = "width-before-scroll-bar";
exports.noScrollbarsClassName = "with-scroll-bars-hidden";
exports.removedBarSizeVariable = "--removed-body-scroll-bar-size";
export default exports;
export const __esModule = exports.__esModule,
      zeroRightClassName = exports.zeroRightClassName,
      fullWidthClassName = exports.fullWidthClassName,
      noScrollbarsClassName = exports.noScrollbarsClassName,
      removedBarSizeVariable = exports.removedBarSizeVariable;